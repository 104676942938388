const translations = {
    en: {
      translation: {
        HOOKAH: "Hookah",
        PASTRIES: "Pastries",
        PAN_CAKE: "Pancake",
        WAFFLE: "Waffle",
        CREPE: "Crepe",
        JUICES: "Juices",
        COLD_DRINKS: "Cold Drinks",
        HOT_DRINKS: "Hot Drinks",
        IQD: "IQD",
        MORE: "More",
        menu: "Menu",
        'Add Dish': "Add Dish",
        'Add': "Add",
        'All Dishes': "All Dishes",
        'Add New User': "Add New User",
        IMAGE: "Image",
        NAME_UR: "Name in Urdu",
        NAME_FA: "Name in Persian",
        NAME_EN: "Name in English",
        NAME_AR: "Name in Arabic",
        DESC_UR: "Description in Urdu",
        DESC_FA: "Description in Persian",
        DESC_EN: "Description in English",
        DESC_AR: "Description in Arabic",
        PRICE: "Price",
        CATEGORY: "Category",
        search:"Search",
        "warning": "Warning",
        "delete_warning_message": "This action will permanently delete the data. Are you sure you want to proceed?",
        "no_cancel": "No, Cancel",
        "yes_delete": "Yes, Delete",
        "Log Out":"Log Out",
        contact:"Contact",
        instagram:"Instagram",
        facebook:"Facebook",
        call:"Call us",
        login:"Login",
        username:"Username",
        password:"Password",
        "confirm password":"Confirm Password",
        loading:"Loading",
        "delete dish":"Delete Dish",
        "All Users":"All Users",
        "delete":"Delete",
        "Join Date":"Join Date",
        "hide dish":"Hide Dish",
        "show dish":"Show Dish",
        "Done":"Done",
        "Manage Categories":"Manage Categories",
        "Category Name (Arabic)": "Category Name (Arabic)",
        "Category Name (English)": "Category Name (English)",
        "Category Name (Persian)": "Category Name (Persian)",
        "Category Name (Urdu)": "Category Name (Urdu)",
        "Category Name": "Category Name",
        "This User is Unauthorized":"This User is Unauthorized",
        "edit":"Edit",
        "Sorry, no results were found.":"Sorry, no results were found."
      }
    },
    ar: {
      translation: {
        HOOKAH: "الأراكيل",
        PASTRIES: "المعجنات",
        PAN_CAKE: "بان كيك",
        WAFFLE: "الوافل",
        CREPE: "الكريب",
        JUICES: "العصائر",
        COLD_DRINKS: "المشروبات الباردة",
        HOT_DRINKS: "المشروبات الساخنة",
        IQD: "د.ع",
        MORE: "المزيد",
        menu: "القائمة",
        'Add Dish': "أضافة طبق",
        'Add': "أضافة",
        'All Dishes': "جميع الأطباق",
        'Add New User': "أضافة مستخدم جديد",
        IMAGE: "الصورة",
        NAME_UR: "الأسم بالأردو",
        NAME_FA: "الأسم بالفارسية",
        NAME_EN: "الأسم بالأنجليزية",
        NAME_AR: "الأسم بالعربية",
        DESC_UR: "الوصف بالأردو",
        DESC_FA: "الوصف بالفارسية",
        DESC_EN: "الوصف بالأنجليزية",
        DESC_AR: "الوصف بالعربية",
        PRICE: "السعر",
        CATEGORY: "الفئة",
        search:"البحث",
        "warning": "تحذير",
        "delete_warning_message": "سيؤدي هذا النشاط إلى حذف البيانات بشكل نهائي. هل أنت متأكد من قيامك بهذا الفعل؟",
        "no_cancel": "لا، إلغاء",
        "yes_delete": "نعم، حذف",
        "Log Out":"تسجيل الخروج",
        contact:"تواصل",
        instagram:"انستغرام",
        facebook:"فيسبوك",
        call:"أتصل",
        login:"تسجيل الدخول",
        username:"أسم المستخدم",
        password:"كلمة المرور",
        "confirm password":"تأكيد كلمة المرور",
        loading:"جار التحميل...",
        "delete dish":"حذف الطبق",
        "All Users":"جميع المستخدمين",
        "delete":"حذف",
        "Join Date":"تاريخ الأنضمام",
        "hide dish":"أخفاء الطبق",
        "show dish":"أظهار الطبق",
        "Done":"أتمام",
        "Manage Categories":"أدارة الفئات",
        "Category Name (Arabic)": "اسم الفئة (بالعربية)",
        "Category Name (English)": "اسم الفئة (بالإنجليزية)",
        "Category Name (Persian)": "اسم الفئة (بالفارسية)",
        "Category Name (Urdu)": "اسم الفئة (بالأردية)",
        "Category Name": "اسم الفئة",
        "This User is Unauthorized":"هذا المستخدم غير مصرح له بالدخول",
        "edit":"تعديل",
        "Sorry, no results were found.":"عذرا, لم يتم ايجاد اي نتيجة"
      }
    },
    fa: {
      translation: {
        HOOKAH: "قلیان",
        PASTRIES: "شیرینی",
        PAN_CAKE: "پنکیک",
        WAFFLE: "وافل",
        CREPE: "کرپ",
        JUICES: "آبمیوه",
        COLD_DRINKS: "نوشیدنی‌های سرد",
        HOT_DRINKS: "نوشیدنی‌های گرم",
        IQD: "دینار عراق",
        MORE: "بیشتر",
        menu: "منو",
        'Add Dish': "یک ظرف اضافه کنید",
        'Add': "اضافه کردن",
        'All Dishes': "همه ی غذاها",
        'Add New User': "یک کاربر جدید اضافه کنید",
        IMAGE: "تصویر",
        NAME_UR: "نام به اردو",
        NAME_FA: "نام به فارسی",
        NAME_EN: "نام به انگلیسی",
        NAME_AR: "نام به عربی",
        DESC_UR: "توضیحات به اردو",
        DESC_FA: "توضیحات به فارسی",
        DESC_EN: "توضیحات به انگلیسی",
        DESC_AR: "توضیحات به عربی",
        PRICE: "قیمت",
        CATEGORY: "دسته‌بندی",
        search:"تحقیق کنید",
        "warning": "هشدار",
        "delete_warning_message": "این عمل منجر به حذف دائمی داده‌ها خواهد شد. آیا از انجام این کار مطمئن هستید؟",
        "no_cancel": "نه، لغو",
        "yes_delete": "بله، حذف",
        "delete":"حذف",
        "Log Out":"از سیستم خارج شوید",
        contact:"تماس",
        instagram:"اینیستاگرام",
        facebook:"فيسبوك",
        call:"تماس بگیرید",
          "login": "ورود",
          "username": "نام کاربری",
          "password": "کلمه عبور",
          "confirm password": "تأیید کلمه عبور",
        loading:"در حال بارگذاری...",
        "delete dish":"حذف غذا",
        "All Users":"همه کاربران",
        "Join Date":"تاریخ عضویت",
        "hide dish":"پنهان نمودن غذا",
        "show dish":"نمایش غذا",
        "Done":"تکمیل",
        "Category Name (Arabic)": "نام دسته‌بندی (عربی)",
        "Category Name (English)": "نام دسته‌بندی (انگلیسی)",
        "Category Name (Persian)": "نام دسته‌بندی (فارسی)",
        "Category Name (Urdu)": "نام دسته‌بندی (اردو)",
        "Category Name": "نام دسته‌بندی",
        "This User is Unauthorized":"این کاربر مجاز به ورود به سیستم نیست",
        "edit":'ویرایش',
        "Sorry, no results were found.":"با عرض پوزش، هیچ نتیجه ای یافت نشد."
        
      }
    },
    ur: {
      translation: {
        HOOKAH: "حقہ",
        PASTRIES: "پیستریز",
        PAN_CAKE: "پین کیک",
        WAFFLE: "وافل",
        CREPE: "کریپ",
        JUICES: "جوس",
        COLD_DRINKS: "ٹھنڈے مشروبات",
        HOT_DRINKS: "گرم مشروبات",
        IQD: "عراقی دینار",
        MORE: "مزید",
        menu: "فہرست",
        'Add Dish': "ایک ڈش شامل کریں",
        'Add': "شامل کریں",
        'All Dishes': "تمام پکوان",
        'Add New User': "ایک نیا صارف شامل کریں",
        IMAGE: "تصویر",
        NAME_UR: "نام اردو میں",
        NAME_FA: "نام فارسی میں",
        NAME_EN: "نام انگریزی میں",
        NAME_AR: "نام عربی میں",
        DESC_UR: "تفصیل اردو میں",
        DESC_FA: "تفصیل فارسی میں",
        DESC_EN: "تفصیل انگریزی میں",
        DESC_AR: "تفصیل عربی میں",
        PRICE: "قیمت",
        CATEGORY: "زمرہ",
        search:"تحقیق",
        "warning": "انتباہ",
        "delete_warning_message": "اس عمل کے نتیجے میں ڈیٹا کو مستقل طور پر حذف کر دیا جائے گا۔ کیا آپ اس عمل کو جاری رکھنے کے لئے یقین رکھتے ہیں؟",
        "no_cancel": "نہیں، منسوخ کریں",
        "yes_delete": "ہاں، حذف کریں",
        "delete": "حذف کریں",
        "Log Out":"لاگ آؤٹ کریں۔",
        contact:"رابطه",
        instagram:"انسٹگرام",
        facebook:"فیس بوک",
        call:"ٹیلیفون",
        "login": "لاگ ان",
        "username": "صارف کا نام",
        "password": "پاسورڈ",
        "confirm password": "پاسورڈ کی تصدیق",
        loading:"لوڈ ہو رہا ہے۔...",
        "delete dish":"ڈش کو حذف کریں۔",
        "All Users":"تمام صارفین",
        "Join Date":"شمولیت کی تاریخ",
        "hide dish":"ڈش چھپائیں",
        "show dish":"ڈش دکھائیں",
        "Done":"تکمیل",
        "Manage Categories":"",
        "Category Name (Arabic)": "زمرہ کا نام (عربی)",
        "Category Name (English)": "زمرہ کا نام (انگریزی)",
        "Category Name (Persian)": "زمرہ کا نام (فارسی)",
        "Category Name (Urdu)": "زمرہ کا نام (اردو)",
        "Category Name": "زمرہ کا نام",
        "This User is Unauthorized":"یہ صارف لاگ ان کرنے کا مجاز نہیں ہے۔",
        "edit":"تدوین",
        "Sorry, no results were found.":"معذرت، کوئی نتیجہ نہیں ملا۔"
      }
    }
  };
  
  export default translations;
  