import React, { useEffect, useState } from 'react'
import { BsChevronDown } from 'react-icons/bs'
import axios from 'axios'
import URL from '../URL'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import '../styles/EditDish.css'
function EditDish() {
  const [isDropdownActive, setIsDropdownActive] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [categories, setCategories] = useState("")
  const [currentImage, setCurrentImage] = useState('')
  const [data,setData] = useState({
    names: [],
    description: [],
    price: 0,
    image: "",
    category: "",
    categoryValue:""
  })
  const {t,i18n} = useTranslation()
  const currentLanguage = i18n.language
  function pushMultilingualData(content, language, key) {
    setData((prevData) => {
      const existingIndex = prevData[key].findIndex((item) => item.language === language);
  
      if (existingIndex !== -1) {
        const updatedNames = [...prevData[key]];
        updatedNames[existingIndex].value = content;
        return {
          ...prevData,
          [key]: updatedNames,
        };
      } else {
        return {
          ...prevData,
          [key]: [...prevData[key], { language, value: content }],
        };
      }
    });
  }

  const  [error, setError] = useState("")

  const params = useParams()
  const id = params.id
  async function loadData(){
    await axios.get(`${URL}/menu/${id}`, {withCredentials:true})
                .then(res => {
                    setData(res.data.data)
                    setCurrentImage(res.data.data.image)
                })
                .catch(err => {
                    console.log(err)
                })
    await axios.get(`${URL}/category/all`, {withCredentials:true})
                .then(res => {
                    setCategories(res.data.data)
                    setIsLoading(false)
                    // setCurrentImage(res.data.data.image)
                })
                .catch(err => {
                    console.log(err)
                })
  }
  useEffect(() => {
    if(isLoading){
        loadData()
    }
  }, [isLoading])

  const handleImageUpload = async (id, file) => {
    try {
        const formData = new FormData();
        formData.append('image', file);

        const response = await axios.put(`${URL}/menu/${id}/update-image`, formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        window.location.reload()

        setError(response.data.message);
    } catch (err) {
        console.error(err);
        alert(err.response?.data?.message || "Error uploading image");
    }
};
  async function edit(data){
    await axios.put(`${URL}/menu/${id}/edit`, data, {withCredentials:true})
                .then(res => {
                    setError(res.data.message)
                    if(res.status === 200 && (currentImage === data.image)){
                        window.location.reload()
                    }
                })
                .catch(err => {
                    console.log(err)
                    setError(err.response.data.message)
                })
    await handleImageUpload(id, data.image)
  }
  return (
    <div className='form-page edit-dish-container'>
        <div className='image-preview'>
            <img src={`${URL.replace(/\/api$/, '')}/imgs/${currentImage}`}  loading='lazy' alt={data.names[0]}  />
        </div>
      <form>
        <div className='row'>
          <div className='field'>
            <label>{t('IMAGE')}</label>
            <input type='file' onChange={e => {
              setData(d => {
                return { ...d, image: e.target.files[0] }
              })
            }} required name='image' />
          </div>
        </div>
        <div className='row names-row'>
          <div className='field-container'>
            <label>{t('NAME_AR')}</label>
            <input type='text' name='ar-name' value={data.names.find(d => d.language === 'ar')?.value || ""}  onChange={e => {
              pushMultilingualData(e.target.value, 'ar', "names")
            }} required />
          </div>
          <div className='field-container'>
            <label>{t('NAME_EN')}</label>
            <input type='text' name='en-name' value={data.names.find(d => d.language === 'en')?.value || ""}  onChange={e => {
              pushMultilingualData(e.target.value, 'en', "names")
            }} required />
          </div>
          <div className='field-container'>
            <label>{t('NAME_FA')}</label>
            <input type='text' name='fa-name' value={data.names.find(d => d.language === 'fa')?.value || ""}  onChange={e => {
              pushMultilingualData(e.target.value, 'fa', "names")
            }} required />
          </div>
          <div className='field-container'>
            <label>{t('NAME_UR')}</label>
            <input type='text' name='ur-name' value={data.names.find(d => d.language === 'ur')?.value || ""}  onChange={e => {
              pushMultilingualData(e.target.value, 'ur', "names")
            }} required />
          </div>
        </div>
        <div className='row names-row'>
          <div className='field-container'>
            <label>{t('DESC_AR')}</label>
            <textarea name='ar-desc' id='ar-desc' value={data.description.find(d => d.language === 'ar')?.value || ""}  onChange={e => {
              pushMultilingualData(e.target.value, 'ar', "description")
            }} required></textarea>
          </div>
          <div className='field-container'>
            <label>{t('DESC_EN')}</label>
            <textarea name='ar-desc' id='ar-desc' value={data.description.find(d => d.language === 'en')?.value || ""} onChange={e => {
              pushMultilingualData(e.target.value, 'en', "description")
            }} required></textarea>
          </div>
          <div className='field-container'>
            <label>{t('DESC_FA')}</label>
            <textarea name='ar-desc' id='ar-desc' value={data.description.find(d => d.language === 'fa')?.value || ""} onChange={e => {
              pushMultilingualData(e.target.value, 'fa', "description")
            }} required></textarea>
          </div>
          <div className='field-container'>
            <label>{t('DESC_UR')}</label>
            <textarea name='ar-desc' id='ar-desc' value={data.description.find(d => d.language === 'ur')?.value || ""} onChange={e => {
              pushMultilingualData(e.target.value, 'ur', "description")
            }} required></textarea>
          </div>
        </div>
        <div className='row two-cols'>
          <div className='field-container'>
            <label>{t('PRICE')}</label>
            <input type='number' name='price' min={0} value={data.price} required onChange={e => {
              setData(d => {
                return { ...d, price: e.target.value }
              })
            }} />
          </div>
          <div className='field-container relatived' onClick={() => { setIsDropdownActive(!isDropdownActive) }}>
            <label>{t('CATEGORY')}</label>
            <input type='text' value={t(categories.length > 0 ? categories.find(d => d._id === data.category).names.find(n => n.language === currentLanguage)?.value : null)} name='category' required disabled />
            <BsChevronDown className='icon' style={{ transform: isDropdownActive ? "rotate(180deg)" : 'rotate(0)' }} />
            <div className={isDropdownActive ? 'dropdown active' : 'dropdown'}>
              <ul>
                {
                  categories.length > 0 ? categories.map((category, i) => {
                    return <li key={i} onClick={() => {
                      setIsDropdownActive(false)
                      setData(d => {
                        return { ...d, categoryValue: category.name, category: category._id }
                      })
                    }}>{category.names.find(n => n.language === currentLanguage)?.value}</li>
                  }) : null
                }
              </ul>
            </div>
          </div>
        </div>
        <div className='row'>
          <button onClick={e => {
            e.preventDefault()
            edit(data)
          }}>{t('Done')}</button>
        </div>
      </form>
      <div className='errors-container'>
        <p>
          {error}
        </p>
      </div>
    </div>
  )
}

export default EditDish